import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/messaging';
import axios from '@/config/axios';
import useSound from 'vue-use-sound';
import notificationSfx from '@/static/Message.mp3';

const firebaseInit = function() {
    console.log('Firebase initialized');
    const firebaseConfig = {
        apiKey: "AIzaSyBAwtiqiMG_2VPVzPfBlnK5imb_NHSKNwU",
        authDomain: "tgcrm-66291.firebaseapp.com",
        projectId: "tgcrm-66291",
        storageBucket: "tgcrm-66291.appspot.com",
        messagingSenderId: "1066948724179",
        appId: "1:1066948724179:web:3dd6589444b3c8db751b26"
    };
    firebase.initializeApp(firebaseConfig);


    const audioFile = new Audio(notificationSfx);

    const play = function() {
        audioFile.play();
    }

    if(localStorage.hasOwnProperty('ios_token')) {
        sendIosTokenToServer(localStorage.getItem('ios_token'));
    }

    if ("Notification" in window && firebase.messaging.isSupported()) {
        const messaging = firebase.messaging();
        console.log('messaging: ', messaging);
        try {
            messaging
                .getToken({
                    vapidKey:
                        "BPFyu7olD8nCMoh7P1nOq4vIM_4GNdKUyYPpogj5lWQC5oNl66R1sSTNYpPwa08DiTVP3V0izUtkJrnvKUTcZQ4",
                })
                .then((currentToken) => {
                    if (currentToken) {
                        sendTokenToServer(currentToken);
                    } else {
                        console.warn("Failed to get token.");
                    }
                })
                .catch((err) => {
                    console.log(
                        "An error occurred while retrieving token. ",
                        err
                    );
                    setTokenSentToServer(false);
                });
        } catch (e) {
            console.log(e);
        }


        messaging.onMessage((payload) => {
            console.log("Message received. firebase.js ", payload);

            if(document.visibilityState === "visible") {
                console.log('use sound!');
                play();
             return;
            }

            var n = Notification(
                payload.notification.title,
                payload.notification
            );

            n.onclick = function() {
                notification.close();
                window.parent.focus();
            };
        });
    }
};

const isTokenSentToServer = function(currentToken) {
    return (
        window.localStorage.getItem("sentFirebaseMessagingToken") ===
        currentToken
    );
};

const setTokenSentToServer = function(currentToken) {
    window.localStorage.setItem(
        "sentFirebaseMessagingToken",
        currentToken ? currentToken : ""
    );
};

const sendTokenToServer = function(currentToken) {
    if (!isTokenSentToServer(currentToken)) {
        axios
            .post("firebase/device/token", { token: currentToken })
            .then((data) => {
                if (data.data.status) {
                    setTokenSentToServer(currentToken);
                }
            });
    }
}

const sendIosTokenToServer = function(currentToken) {
    axios
        .post("firebase/device/token", { token: currentToken, type: 'ios' })
        .then((data) => {
            if (data.data.status) {
                console.log('ios token set');
            }
        });
}

export { firebaseInit, isTokenSentToServer, setTokenSentToServer, sendTokenToServer };
